import React from "react";
import "./DayezaPractica.css";

export const DayezaPractica = () => {
  return (
    <div className="page">
      dayezaPractica
      <p>AQUI PUEDES HACER TUS PRUEBAS DE HTML, CSS Y JAVASCRIPT</p>
      <p>adfasdf</p>
      <p>adfasdf</p>
      <p>adfasdf</p>
      <p>adfasdf</p>
    </div>
  );
};

export default DayezaPractica;
