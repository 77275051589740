import React from 'react'
import './Etiqueta.css'

const Etiqueta = ( {interes} ) => {
  return (
    <div className='etiqueta-interes'>
        {interes}
    </div>
  )
}

export default Etiqueta